import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default  ({ title, children, customClasses }) => {
  useStyles(classes);
  
  return (
      <div className={customClasses ? `${classes.container} ${customClasses.container}` : classes.container}>
        <div className={classes.header}>
          <h3 className={classes.title}>
            { title }
          </h3>
        </div>
        <div className={customClasses ? `${classes.content} ${customClasses.content}` : classes.content}>
            { children }
        </div>
      </div>
  );
}